import React from 'react';
import classnames from 'classnames';
import Title from './title';
import Card from './card';
import Slider from "react-slick";

import { priceSpiderRebind } from "../services/util";

// Styles
import * as styles from '../styles/scss/components/featuredProduct.module.scss';

const sliderSpeed = 500; // ms

const FeaturedProduct = ({
    className = '',
    products = [],
}) => {

    const [newProducts] = React.useState(() => {
        let featuredProducts = products;
        if (products.reviews && products.reviews.length > 0) {
            featuredProducts.forEach((product, key) => {
                if (product && product.bazaarVoiceId) {
                    const review = products.reviews.find(e => e.id === product.bazaarVoiceId);
                    Object.assign(featuredProducts[key], {
                        reviewStats: review,
                    });
                }
            });
        }
        return featuredProducts;
    });

    React.useEffect(() => {
        priceSpiderRebind();
    });

    React.useEffect(() => {
        const pricespiderscript = document.createElement("script")
        pricespiderscript.src = "//cdn.pricespider.com/1/lib/ps-widget.js"
        pricespiderscript.async = true
        document.body.appendChild(pricespiderscript)
    }, []);


    // Custom Arrows for carousels
    const CustomArrow = (props) => {
        return (
            <div
                className={classnames(props.className, styles.CustomArrowContainer)}
                onClick={props.onClick}
                role ="none" onKeyDown={props.onClick}
            >
                <img
                    alt="Arrow"
                    src="/img/icons/slider-arrowAlternative.svg"
                    id={props.type}
                    className={props.type === "NEXT" ? styles.arrowNext : styles.arrowPrev}
                />
            </div>
        );
    }

    const renderCardProduct = (product) => {
        return (
            <Card
                type="ratedProduct"
                title={product.displayName}
                image={product.listImage ? product.listImage.url : ''}
                product={product}
                slug={product.slug}
                alternativeLook
            >
                {
                    product.teaser && (
                        <p>
                            {product.teaser}
                        </p>
                    )
                }
            </Card>
        );
    }


    const renderCardProductMobile = (product) => {
        return (
            <Card
                type="product"
                title={product.displayName}
                image={product.listImage ? product.listImage.url : ''}
                product={product}
                slug={product.slug}
            >
                {
                    product.teaser && (
                        <p>
                            {product.teaser}
                        </p>
                    )
                }
            </Card>
        );
    }

    const renderMobileSlider = (
        <div
            key={1}
            className={styles.SliderContainer}
        >
            <Slider
                className={styles.CustomSlider}
                initialSlide={0}
                dots
                infinite={false}
                slidesToShow={1}
                slidersToScroll={1}
                autoplay={false}
                speed={sliderSpeed}
                arrows
                swipe
                swipeToSlide={true}
                nextArrow={(
                    <CustomArrow
                        type="NEXT"
                    />
                )}
                prevArrow={(
                    <CustomArrow
                        type="PREV"
                    />
                )}
            >
                {
                    newProducts.slice(0, 2).map((product, idx) => (
                        <div
                            key={idx}
                            className="slide-content"
                        >
                            {renderCardProductMobile(product)}
                        </div>
                    ))
                }
            </Slider>
        </div>
    );

    const renderDesktop = (
        <div
            key={2}
            className={styles.SliderContainer}
        >
            {
                newProducts.slice(0, 2).map((product, idx) => (
                    <div
                        key={idx}
                        className="slide-content"
                    >
                        {renderCardProduct(product)}
                    </div>
                ))
            }
        </div>
    );

    if (newProducts.length === 0) {
        return null;
    }

    return (
        <div
            className={classnames(className, styles.FeaturedProductsMainContainer)}
        >
            {/* Title */}
            <Title
                className={styles.MainTitle}
                value='FEATURED PRODUCTS'
            />

            {/* Content */}
            <div
                className={styles.FeaturedProductsMainContent}
            >
                {/* Bottom Container */}
                {
                    newProducts.length > 0 && (
                        <>
                            <div className={styles.featuredProductsDesktop}>
                                {renderDesktop}
                            </div>
                            <div className={styles.featuredProductsMobile}>
                                {renderMobileSlider}
                            </div>
                        </>
                    )
                }
            </div>

        </div>
    );
}

export default React.memo(FeaturedProduct);
