import React from "react"
import Title from "../components/title"
import Text from "../components/text"
import styles from "../styles/scss/pages/articleDetail.module.scss"
import NewLayout from "../layout/newLayout"
import CtaBanner from "../components/ctaBanner";
import SEO from "../components/seo"
import PageContents from "../components/pageContents"
import HeroSimple from "../components/heroSimple"
import { Link } from "gatsby"
import RelatedArticles from "../components/relatedArticles"
import FeaturedProducts from "../components/featuredProducts"

const ArticleDetail = ({ pageContext }) => {
    var article = pageContext.article
    var nextArticle = pageContext.nextArticle    
    article.seo = {
        title: article.title,
        description: article.teaser,
        twitterCard: null,
    }
    var relatedArticles = article.relatedArticles
    var relatedProducts = article.relatedProducts
    relatedProducts.reviews = pageContext.reviews

    return (
        <NewLayout>
            {article.seo.title && (
                <h1 style={{ display: "none" }}>{article.seo.title}</h1>
            )}
            <SEO page={article} />

            {article.heroImage && <HeroSimple image={article.heroImage.url} />}

            <div className="d-flex flex-column flex-fill">
                <div className={styles.container}>
                    <div className={styles.article}>
                        <div className={styles.titleContainer}>
                            <Title
                                value={article.title}
                                alignLeft
                                className={styles.title}
                            />
                            <Link to={article.category.blog ? '/blog/' : '/newsroom/'} className={styles.goBackLink}>
                                {"<<"} Back
                            </Link>
                        </div>
                        <Text className={styles.description}>
                            <PageContents
                                content={article.content}
                                pageTitle={article.title}
                            />
                        </Text>
                        {nextArticle && article.category.slug == nextArticle.category.slug && (
                            <Link
                                to={`/article/${nextArticle.slug}`}
                                className={styles.goNextLink}
                            >
                                MORE NEWS{">>"}
                            </Link>
                        )}
                    </div>
                    {relatedProducts && relatedProducts.length > 0 && (
                        <FeaturedProducts products={relatedProducts} />
                    )}
                </div>
                {relatedArticles && relatedArticles.length > 0 && (
                    <RelatedArticles articles={relatedArticles} />
                )}
            </div>

            <CtaBanner
                text="WANT TO GET MORE TIPS AND TRICKS?"
                buttonText="SUBSCRIBE TO THE NEWSLETTER"
                buttonUrl="/signup/"
            />
        </NewLayout>
    )
}

export default ArticleDetail
