import React from 'react';
import classnames from 'classnames';
import Title from './title';
import Card from './card';
import Slider from "react-slick";
import Imgix, { buildURL } from "react-imgix"

// Styles
import * as styles from '../styles/scss/components/relatedArticles.module.scss';

const sliderSpeed = 500; // ms

const RelatedArticles = ({
  className = '',
  articles = [],
}) => {

  // Memos
  const groupsOfTwoArticles = React.useMemo(() => {
    let out = [];
    if (articles && articles.length > 0) {
      const perChunk = 2;
      out = articles.reduce((pV, cV, idx) => {
        const ch = Math.floor(idx / perChunk);
        pV[ch] = [].concat((pV[ch] || []), cV);
        return pV;
      }, [])
    }
    return out;
  }, [articles]);

  // Renders

  // Custom Arrows for carousels
  const CustomArrow = (props) => {
    return (
      <div
        role='button'
        tabIndex={0}
        onKeyDown={props.onClick}
        className={classnames(props.className, styles.CustomArrowContainer)}
        onClick={props.onClick}
      >
        <Imgix
          alt="Arrow"
          src="/img/icons/slider-arrowAlternative.svg"
          className={props.type === "NEXT" ? styles.arrowNext : styles.arrowPrev}
          htmlAttributes={{ alt: 'Arrow' }}
        />
      </div>
    );
  }

  const renderCardArticle = (item) => {
    const newSubtitle = item.subtitle ? `: ${item.subtitle}` : "";
    return (
      <Card
        key={item.slug}
        type="image"
        title={`${item.title}${newSubtitle}`}
        image={buildURL(item.heroImage.url, { w: 500, bri: -5, fm: 'jpg' })}
        link={'/article/' + item.slug}
        className={styles.specialCard}
      >
        <p>{item.teaser}</p>
      </Card>
    );
  }

  if (articles.length === 0) {
    return null;
  }

  return (
    <div
      className={classnames(className, styles.RelatedArticlesMainContainer)}
    >

      {/* Content */}
      <div
        className={styles.RelatedArticlesMainContent}
      >
        {/* Title */}
        <Title
          className={styles.MainTitle}
          value='RELATED ARTICLES'
        />

        {/* Bottom Container */}
        {
          articles.length > 0 && (
            <>
              {/* Slider */}
              <div className={styles.desktopSlider}>
                <div
                  key={2}
                  className={styles.SliderContainer}
                >
                  <Slider
                    className={styles.CustomSlider}
                    initialSlide={0}
                    dots
                    infinite={false}
                    slidesToShow={1}
                    slidersToScroll={1}
                    autoplay={false}
                    speed={sliderSpeed}
                    fade
                    arrows
                    nextArrow={(
                      <CustomArrow
                        type="NEXT"
                      />
                    )}
                    prevArrow={(
                      <CustomArrow
                        type="PREV"
                      />
                    )}
                  >
                    {
                      groupsOfTwoArticles.map((group, idx) => (
                        <div
                          key={idx}
                          className="slide-content"
                        >
                          {
                            group[0] && (
                              <div
                                className="slide-content-left"
                              >
                                {renderCardArticle(group[0])}
                              </div>
                            )
                          }

                          {
                            group[1] && (

                              <div
                                className="slide-content-right"
                              >
                                {renderCardArticle(group[1])}
                              </div>
                            )
                          }
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
              <div className={styles.mobileSlider}>
                <div
                  key={1}
                  className={styles.SliderContainer}
                >
                  <Slider
                    className={styles.CustomSlider}
                    initialSlide={0}
                    dots
                    infinite={false}
                    slidesToShow={1}
                    slidersToScroll={1}
                    autoplay={false}
                    speed={sliderSpeed}
                    arrows
                    swipe
                    swipeToSlide={true}
                    nextArrow={(
                      <CustomArrow
                        type="NEXT"
                      />
                    )}
                    prevArrow={(
                      <CustomArrow
                        type="PREV"
                      />
                    )}
                  >
                    {
                      articles.map((product, idx) => (
                        <div
                          key={idx}
                          className="slide-content"
                        >
                          {renderCardArticle(product)}
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            </>
          )
        }
      </div>

    </div>
  );
}

export default React.memo(RelatedArticles);
